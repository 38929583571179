
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu'
import logo from '../images/icons/logo_nav.png';

import Modal from '../components/modal';


function Header({manifest}) {
    const navigate = useNavigate();
    const [address, setAddress] = useState(null);
    const [profiles, setProfiles] = useState([]);
    const [activeProfile, setActiveProfile] = useState(null);
    const [walletButtonText, setWalletButtonText] = useState("Sign In");
    const [isBurgerOpen, setIsBurgerOpen] = useState(false)

    const [showActiveProfileModal, setShowActiveProfileModal] = useState(false);


    useEffect(() => {
      if(!address){
          setWalletButtonText("Sign In");
      }
      else{
          setWalletButtonText(address.slice(0,10)+'...');
      }
    },[address]);

    useEffect(() => {

        async function doLogin(){
            await manifest.Crypto.load();

            if('prod' == manifest.stage && 'main' != manifest.Crypto.network){
                return alert('Must be connected to Ethereum Mainnet');
            }
            const signInRes = await manifest.Api.signIn({address:addy,session_token});
            setAccountInfo({manifest,account:signInRes});
        }

        const addy = localStorage.getItem('eternalActiveAddress');
        const session_token = localStorage.getItem('eternalSessionToken');

        if(addy && addy != 'undefined' && session_token && session_token != 'undefined'){
            //we've got what we need! let's just load account...
            doLogin();
        }

        async function doLogout(){
            localStorage.removeItem('eternalActiveAddress');
            localStorage.removeItem('eternalSessionToken');
            manifest.profiles = [];
            setProfiles([]);
            setAddress(null);
            setWalletButtonText("Sign In");
        }

        async function doAccountCheck(){
            const initialAdd = manifest.Crypto.account;
            await manifest.Crypto.loadAccount();
            const newAdd = manifest.Crypto.account;

            if(!initialAdd){
                return;
            }
            else if(!newAdd || initialAdd != newAdd){
                console.log('addy changed before: ',initialAdd);
                console.log('addy changed after: ',newAdd);
                doLogout();
            }
        };

        setInterval(async () => {
          doAccountCheck();
        },1000);
    },[]);

    useEffect(() => {
        //todo load the UI
        if(profiles.length){
            setActiveProfile(profiles[0].name);
        }
    },[profiles]);


    function setAccountInfo({manifest,account}){
        manifest.profiles = account.profiles;
        setProfiles(account.profiles);
        setAddress(account.address);
        localStorage.setItem('eternalActiveAddress', manifest.Crypto.account);
        if(account.session_token) {
            manifest.session_token = account.session_token.token;
            localStorage.setItem('eternalSessionToken', account.session_token.token);
        }
    };

    async function onSignInClick(){
        if(address) return;
        await doAccountLoad();
    }

    async function doAccountLoad(){
        setWalletButtonText("Loading Wallet ^^^");

        try{
            await manifest.Crypto.load();
            if(!manifest.Crypto.account){
                setWalletButtonText("Sign In");
                return alert('Must connect wallet to Eternal to continue');
            }
            else if('prod' == manifest.stage && 'main' != manifest.Crypto.network){
                return alert('Must be connected to Ethereum Mainnet');
            }
            else{

                const address = manifest.Crypto.account;
                const rando = await manifest.Crypto.makeRando(100);
                let msg = "Sign-in to etrnl.it with your Ethereum account: " +address +"\n\n";
                msg += "URL: https://etrnl.it \n";
                msg += "Random Token: "+rando
                const sig = await manifest.Crypto.sign(msg);
                const signInRes = await manifest.Api.signIn({address,msg,sig});

                if(signInRes){
                    setAccountInfo({manifest,account:signInRes});
                }
                else{
                    setWalletButtonText("Sign In");
                    alert('An error occurred during Sign In');
                }
            }

        }
        catch(err){
            console.log('err: ',err);
            setWalletButtonText("Sign In");
            alert('Failed to SignIn to Eternal')
        }
    }

      const handleIsBurgerOpen = () => {
        setIsBurgerOpen(!isBurgerOpen)
      }

      const closeBurgerMenu = () => {
        setIsBurgerOpen(false)
      }

      function onProfileBtnClick(){
          console.log('active profile click');
          console.log('profiles: ',profiles);
          setShowActiveProfileModal(true);
      }

      function onProfileModalClick(one,two){
          setShowActiveProfileModal(false);
          closeBurgerMenu();
      }

    function onSearchfieldChange(event){
        console.log(event);
        console.log(event.target.value);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          var val = event.target.value;
          if(val.length > 8){
              return alert('profile names are max 8 characters');
          }
          if(!val.match(/^[0-9a-zA-Z]+$/)){
              return alert('Profile names must be alphanumeric characters');
          }
          navigate("/search/"+val.toLowerCase());
          event.target.value = '';
        }
      };

    return (

        <>

        <Modal
            title="Profiles"
            show={showActiveProfileModal}
            setShow={setShowActiveProfileModal}
            content={
                (profiles)
                ? profiles.map(prof => {
                    return <div className="profileModalItem" onClick={onProfileModalClick} profile={prof} key={prof.name}>
                            <h5>@{prof.name}</h5>
                            <Link to={"/profile/"+prof.name}>
                                <span className="profileModalBtns" > View Profile </span>
                            </Link>
                            <Link to={"/profile/"+prof.name+"/createPost"}>
                                <span className="profileModalBtns"> Create Post </span>
                            </Link>
                            <Link to={"/profileEdit/"+prof.name}>
                                <span className="profileModalBtns"> Edit Profile </span>
                            </Link>
                            </div>
                })
                : ""
            }
        />

        <header className="transparent header-light scroll-light">
            <div className="container header-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="de-flex sm-pt10 header-info">
                            <div className="de-flex-col">

                                <div className="de-flex-col">

                                    <div >
                                        <Link to="/about">
                                            <img src={logo} className="headerLogo"/>
                                        </Link>
                                    </div>
                                </div>
                                {/*
                                <form  class="row" id='form_sb' >
                                    <div class="col text-center">
                                        <div class="spacer-10"></div>

                                        <input  onKeyDown={handleKeyDown} className="xs-hide" id='quick_search' name='quick_search' placeholder="Search Profiles..." type='text' />
                                         <a href="#" id="btn-submit"><i class="arrow_right"></i></a>
                                    </div>
                                </form>
                                */}
                                <div className="de-flex-col">
                                    <input onKeyDown={handleKeyDown} id="quick_search" className="header-search-input" name="quick_search" placeholder="Search Profiles..." type="text" />
                                </div>

                            </div>
                            <div className="de-flex-col header-col-mid">
                                <ul id="mainmenu">

                                    <li>
                                        <Link to="/collections">Collections</Link>
                                    </li>
                                    <li>
                                        <Link to="/posts">Posts</Link>
                                    </li>
                                    {/*
                                    <li>
                                        <Link to="/about">About</Link>
                                    </li>
                                    */}
                                    {
                                        (activeProfile)
                                        ? <li>
                                            <Link onClick={onProfileBtnClick} >Profile</Link>
                                        </li>
                                        : ""
                                    }

                                </ul>
                                <div className="menu_side_area">
                                    <Link onClick={onSignInClick} id="connect-wallet-btn"className="btn-main btn-wallet">
                                        {walletButtonText}
                                    </Link>

                                </div>

                                <Menu right id="burgerMenu"
                                    isOpen={isBurgerOpen}
                                    onOpen={handleIsBurgerOpen}
                                    onClose={handleIsBurgerOpen}
                                >
                                    <Link onClick={onSignInClick} className="btn-main btn-wallet" style={{'marginBottom':'30px'}} >
                                        {walletButtonText}
                                    </Link>
                                    {
                                        (activeProfile)
                                        ? <li>
                                            <Link onClick={onProfileBtnClick}>Profile</Link>
                                        </li>
                                        : ""
                                    }
                                    <Link onClick={closeBurgerMenu} className="burgerMenuItem" to="/about">About</Link>
                                    <Link onClick={closeBurgerMenu} className="burgerMenuItem" to="/posts">Posts</Link>
                                    <Link onClick={closeBurgerMenu} className="burgerMenuItem" to="/search">Search</Link>
                                    <Link onClick={closeBurgerMenu} className="burgerMenuItem" to="/collections">Collections</Link>
                                    <Link onClick={closeBurgerMenu} className="burgerMenuItem" to="/whitePaper">Whitepaper</Link>
                                 </Menu>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        </>
    )

}

export default Header;
