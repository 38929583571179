import Web3 from 'web3';
import Web3Provider from 'react-web3-provider';
import TruffleContract from 'truffle-contract';
import EternalContract from '../contract_abi/Eternal.json';



function stringToBytes32(str){
    var hex = window.web3.utils.asciiToHex(str);
    hex = window.web3.utils.padRight(hex, 64);
    return hex;
}

function bytes32ToString(hex){
    return window.web3.utils.hexToUtf8(hex)
}

class CryptoClass {

    nameToId(str){
        var nameToIdMap = {
              "0": "10",
              "1": "11",
              "2": "12",
              "3": "13",
              "4": "14",
              "5": "15",
              "6": "16",
              "7": "17",
              "8": "18",
              "9": "19",
              "a": "20",
              "b": "21",
              "c": "22",
              "d": "23",
              "e": "24",
              "f": "25",
              "g": "26",
              "h": "27",
              "i": "28",
              "j": "29",
              "k": "30",
              "l": "31",
              "m": "32",
              "n": "33",
              "o": "34",
              "p": "35",
              "q": "36",
              "r": "37",
              "s": "38",
              "t": "39",
              "u": "40",
              "v": "41",
              "w": "42",
              "x": "43",
              "y": "44",
              "z": "45"
          };
        var id = "";
        for(var x in str){
            var char = str[x].toLowerCase();
            id+=nameToIdMap[char];
        }
        return parseInt(id);
    }

    idToName (id){

        var idToNameMap = {
            "10": "0",
            "11": "1",
            "12": "2",
            "13": "3",
            "14": "4",
            "15": "5",
            "16": "6",
            "17": "7",
            "18": "8",
            "19": "9",
            "20": "a",
            "21": "b",
            "22": "c",
            "23": "d",
            "24": "e",
            "25": "f",
            "26": "g",
            "27": "h",
            "28": "i",
            "29": "j",
            "30": "k",
            "31": "l",
            "32": "m",
            "33": "n",
            "34": "o",
            "35": "p",
            "36": "q",
            "37": "r",
            "38": "s",
            "39": "t",
            "40": "u",
            "41": "v",
            "42": "w",
            "43": "x",
            "44": "y",
            "45": "z"
        };

        var str = "";
        var index = 0;
        while(id[index]){
            str+=idToNameMap[id[index]+id[index+1]];
            index+=2;
        }
        return str;
    }

    contracts = {};
    profiles = [];

    async load() {
        //load app
        await this.loadWeb3();
        await this.loadNetwork();
        await this.loadAccount();
        await this.loadContract();
        return this;
    }


    // https://medium.com/metamask/https-medium-com-metamask-breaking-change-injecting-web3-7722797916a8
     async loadWeb3() {
       if (typeof window.web3 !== 'undefined') {
         this.web3Provider = window.web3.currentProvider
         window.web3 = new Web3(window.web3.currentProvider)
       } else {
         window.alert("Please connect to Metamask.")
       }


       // Modern dapp browsers...
       if (window.ethereum) {
         this.web3 = new Web3(window.ethereum)
         try {
           // Request account access if needed
           await window.ethereum.enable()
           // Acccounts now exposed
         } catch (error) {
           // User denied account access...
         }
       }
       // Legacy dapp browsers...
       else if (window.web3) {
         this.web3Provider = window.web3.currentProvider
         this.web3 = new Web3(window.web3.currentProvider)
         // Acccounts always exposed
         this.web3.eth.sendTransaction({})
       }
       // Non-dapp browsers...
       else {
         console.log('Non-Ethereum browser detected. You should consider trying MetaMask!')
       }
     }

     async loadNetwork(){
         this.network = await this.web3.eth.net.getNetworkType();
     }

     async sign(msg){

         var sig = null;
         try{
             if(window.ethereum){
                  sig = await window.ethereum.request({
                     method: 'personal_sign',
                     params: [msg, this.account],
                 });

             }
             // else if(window.web3){
             //     console.log('inside window.web3');
             //     sig = await window.web3.eth.accounts.sign(msg, rando);
             // }
             else{
                 console.log('no web3 available...')
             }
         }
         catch(err){
             console.log('err: ',err);

         }

        // return sign;
        return sig;
     }

     async makeRando(length) {
         let result = '';
         const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
         const charactersLength = characters.length;
         let counter = 0;
         while (counter < length) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
           counter += 1;
         }
         return result;
     }

     async loadAccount() {

         try{
             var accounts = [];
             if(window.ethereum){
                 accounts = await window.ethereum.request({method: 'eth_accounts'});
             }
             else if(window.web3 && window.web3.eth){
                 accounts = await window.web3.eth.getAccounts();
             }
             else{
                 this.account = null;
                 return null;
             }

             this.account = accounts[0];

             if(window.web3 && window.web3.eth){
                 window.web3.eth.defaultAccount = accounts[0];
             }

             return this.account;
         }
         catch(err){
             this.account = null;
             return null;
         }

     }

     async getERC1155TokenURI(tokenId, tokenContract){
         const uriABI = [
             {
                 "constant":true,
                 "inputs":[
                     {
                         "internalType": "uint256",
                         "name": "_id",
                         "type": "uint256"
                     }
                 ],
                 "name": "uri",
                 "outputs":[
                     {
                         "internalType":"string",
                         "name":"",
                         "type":"string"
                     }
                 ],
                 "payable": false,
                 "stateMutability": "view",
                 "type": "function"
             }
         ];
         const contract = new window.web3.eth.Contract(uriABI, tokenContract);

        const result = await contract.methods.uri(tokenId).call()

        console.log(result); // https://nftdata.parallelnft.com/api/parallel-alpha/ipfs/QmSwnqTmpwvZH51Uv47opPUxGYx2nknYmGoKoRJQRMDcLL


        return result;
     }

     async getERC721TokenURI(tokenId, tokenContract){
         const tokenURIABI = [
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "tokenId",
                        "type": "uint256"
                    }
                ],
                "name": "tokenURI",
                "outputs": [
                    {
                        "internalType": "string",
                        "name": "",
                        "type": "string"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            }
        ];
         const contract = new window.web3.eth.Contract(tokenURIABI, tokenContract)

         const result = await contract.methods.tokenURI(tokenId).call()

        console.log(result); // https://nftdata.parallelnft.com/api/parallel-alpha/ipfs/QmSwnqTmpwvZH51Uv47opPUxGYx2nknYmGoKoRJQRMDcLL
        return result;
     }

     async loadContract() {

         // console.log(EternalContract);
        // Create a JavaScript version of the smart contract
        this.contracts.Eternal = TruffleContract(EternalContract)
        this.contracts.Eternal.setProvider(this.web3Provider)

        // Hydrate the smart contract with values from the blockchain
        this.eternal = await this.contracts.Eternal.deployed()

      }

      async getPosts(filter){
          const eventObj = {
                fromBlock: 0,
                toBlock: 'latest',
                filter
            };

          var events = await this.eternal.getPastEvents('Post', eventObj);
            const posts = [];
            this.allProfiles = [];

            //load Posts in reverse chronological order
            for(var x = events.length - 1; x >= 0; x--){
                var event = events[x];
                var profileId = event.returnValues.profile;
                var profileName = this.idToName(profileId);
                var channel = '';
                try{
                    channel = bytes32ToString(event.returnValues.channel);
                }
                catch(err){
                    console.log('invalid channel...',err);
                }

                posts.push({
                    data: JSON.parse(event.returnValues.content),
                    content: event.returnValues.content,
                    blockNumber: event.blockNumber,
                    blockHash: event.blockHash,
                    transactionHash: event.transactionHash,
                    profileId,
                    profileName,
                    channel
                });
                if(-1 == this.allProfiles.indexOf(profileName)) this.allProfiles.push(profileName);
            }
            return posts;
      }

     async getAllPosts() {
           return this.getPosts();
      }


     async getPostsForProfile(_profile) {
         // console.log('loading posts for profile: ',_profile);

         return this.getPosts({
             "profile": this.nameToId(_profile)
         });

      }

     async getPostsForChannel(_channel) {
          console.log('loading posts for _channel: ',_channel);

          return this.getPosts({
              "channel": stringToBytes32(_channel),
              // "profile": this.this.nameToId("eternal")
          });

       }

//todo support collection pricing
     async getMintPrice(_profile){

         // var collection = await this.getCollection(_profile);
         // if(collection){
         //     var weiPrice = await this.getCollectionMintPrice(collection);
         // }
         // else{
             if(!this.mintPrices){
                 await this.getMintPrices();
             }
             var profLength = _profile.length;
             var weiPrice = this.mintPrices[profLength-1].toString();
         // }
         const ethPrice = weiPrice/1000000000000000000;
         return ethPrice;
      }

     async getMintPrices(){
         // this.mintPrices = await this.eternal.getProfileMintPrice();
         this.mintPrices = [
             1280000000000000000,
             640000000000000000,
             320000000000000000,
             160000000000000000,
             80000000000000000,
             40000000000000000,
             20000000000000000,
             10000000000000000
         ];

         return this.mintPrices;
     };

     async getContractValue() {
          var res = await this.eternal.getContractValue();
          this.contractValue = window.web3.utils.fromWei(res.toString(), 'ether');
      }

     async getProfileOwner(_profile) {
          var res = await this.eternal.ownerOf(this.nameToId(_profile));
          return res;
      }

     async mintProfile(profileName) {
            const profileId = this.nameToId(profileName)
            const ethPrice = await this.getMintPrice(profileName);
            const priceWei = ethPrice*1000000000000000000;
            const response = await this.eternal.mintProfile(profileId, { from: this.account, value: priceWei });

            console.log(response);
            return response;
       }

      async createPost({message,profileName,channel}) {

           const msg = JSON.stringify(message);
           const profileId = this.nameToId(profileName);
           const channelBytes = stringToBytes32(channel || "");
           const txObj = { from: this.account};

           const response = await this.eternal.createPost(profileId, channelBytes, msg, txObj);
           console.log(response);
           return response;
       }

       async checkIfOwner(profileName){
           const profileId = this.nameToId(profileName);
           const owner = await this.eternal.ownerOf(profileId);
           console.log('ownerOf: ',profileName, owner);
           return (owner.toLowerCase() == this.account);
       }

       async getCollection(profileName){
           const profileId = this.nameToId(profileName);
           console.log('profileId: ',profileId);
           const collection = await this.eternal.getCollection(profileId);
           if(collection != 0x00) return bytes32ToString(collection);
           return false;
       }

       async getCollectionMintPrice(collection){
           const collectionBytes = stringToBytes32(collection);
           const mintPrice = await this.eternal.getCollectionMintPrice(collectionBytes);
           return mintPrice.toString();
       }

       //admin functions
       async updateMintPrices(mintPrices) {
           console.log(mintPrices);
            const response = await this.eternal.updateProfileMintPrice(mintPrices, { from: this.account});
            console.log(response);
            return response;
        }

       async addToCollection({collection,profileNames}) {

           const profileIds = [];
           for(var x in profileNames){
               profileIds.push(this.nameToId(profileNames[x]));
           }
           const collectionBytes = stringToBytes32(collection);
            const response = await this.eternal.addToCollection(profileIds, collectionBytes, { from: this.account});
            console.log(response);
            return response;
        }

        async updateCollectionMintPrice({collection,mintPrice}) {

            const collectionBytes = stringToBytes32(collection);
             const response = await this.eternal.updateCollectionMintPrice(collectionBytes, mintPrice.toString(), { from: this.account});
             console.log(response);
             return response;
         }




};



export default CryptoClass;
