import React, { useState, useEffect } from 'react';

import { Link, useNavigate, useLocation } from "react-router-dom";

function Sidebar({post}) {
    const navigate = useNavigate();
    const location = useLocation();

    const [availableProfiles, setAvailableProfiles] = useState([])
    const [popularProfiles, setPopularProfiles] = useState([])
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isSticky, setIsSticky] = useState(false);
    const [sidebarStyle, setSidebarStyle] = useState({"position":"relative"});


    function handleScroll(yum){

        const windowWidth = window.innerWidth;

        if(windowWidth < 770){
            setSidebarStyle({"diplay":"none"})
        }
        else{
            const position = window.pageYOffset;
            if(position > 230 ){
                // setSidebarStyle({"position":"fixed",top:50})
            }
            else{
                // setSidebarStyle({"position":"relative"});
            }
        }

    };


    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        setAvailableProfiles(['yes','no','cool','eee','beezy','douche']);
        setPopularProfiles(['eternal','big','pew','sw','tb','hmc','joerogan','coke','dog','pony']);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },[]);

    return (
        <div className="col-md-4 sidebar" style={sidebarStyle}>
            <span className="filter__l">Available Profiles</span>
            <div className="spacer-half"></div>
            <div className="clearfix"></div>
            <ul className="activity-filter">
            {
                availableProfiles.map((profile) => (
                    <Link to={"/profile/"+profile}  key={new Date().getTime()+profile}>
                        <li className="filter_by_sales"><i className="fa fa-shopping-basket"></i>
                            {profile}
                        </li>
                    </Link>
                ))
            }

            {/*
                <li className="filter_by_sales"><i className="fa fa-shopping-basket"></i>Sales</li>
                <li className="filter_by_likes"><i className="fa fa-heart"></i>Likes</li>
                <li className="filter_by_offers"><i className="fa fa-gavel"></i>Offers</li>
                <li className="filter_by_followings"><i className="fa fa-check"></i>Followings</li>
            */}

            </ul>
            <div className="spacer-half"></div>
            <div className="spacer-half"></div>
            <span className="filter__l">Popular Profiles</span>
            <div className="spacer-half"></div>
            <div className="clearfix"></div>
            <ul className="activity-filter">
            {
                popularProfiles.map((profile) => (
                    <Link to={"/profile/"+profile}  key={new Date().getTime()+profile}>
                        <li className="filter_by_sales"><i className="fa fa-shopping-basket"></i>
                            {profile}
                        </li>
                    </Link>
                ))
            }


            </ul>
        </div>
    )

}

export default Sidebar;
