import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import Post from '../components/post.js';


function ProfileView({manifest, profileName, posts}) {
    const navigate = useNavigate();

    const [isOwner, setIsOwner] = useState(false);
    const [profileImg, setProfileImg] = useState(null);

      useEffect(() => {

          const loadProfileInfo = async () => {
              if(!manifest.Crypto.eternal){
                  await manifest.Crypto.load();
              }

              var isOwner = await manifest.Crypto.checkIfOwner(profileName);
              console.log('isOwner: ',isOwner);
              setIsOwner(isOwner);

              const urlPrefix = (manifest.stage == 'prod') ? '' : 'dev-';
              const profileId = await manifest.Crypto.nameToId(profileName);
              const imgUrl = 'https://s3.us-west-2.amazonaws.com/'+urlPrefix+'img.etrnl.it/'+profileId+'.png';
              setProfileImg(imgUrl);
          }
          loadProfileInfo();
      },[profileName]);


      function onCreateNewPostClick(){
          console.log('inside onCreateNewPostClick');

          var newPath = "/profile/"+profileName+"/createPost";
          navigate(newPath);
      }

    return (

         <div className="de-retro">
            <div className="wrapper">

                <div className="no-bottom no-top content" >

                    <section id="profile_banner" aria-label="section" className="text-light border-bottom" data-bgimage="url(images/background/9.jpg) center">
                    </section>

                    <section aria-label="section" className="d_coll no-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                   <div className="d_profile">
                                            <div className="profile_avatar">
                                                <div className="d_profile_img">
                                                    <img src={profileImg}
                                                    onError={(e: any) => {setProfileImg('https://dev-img.etrnl.it/empty1.png')}}
                                                    alt=""/>
                                                    {/* <i className="fa fa-check"></i> */}
                                                </div>

                                                <div className="profile_name">
                                                    <h4>
                                                        @{profileName}
                                                        <div className="clearfix"></div>


                                                    </h4>
                                                </div>
                                            </div>
                                            {/*
                                            <button onClick={onCreateNewPostClick} className="btn-main btn-wallet"><i ></i><span>Create New Post</span></button>
                                            */}
                                            {
                                                isOwner
                                                ? <button onClick={onCreateNewPostClick} className="btn-main btn-wallet"><i ></i><span>Create New Post</span></button>
                                                : ""
                                            }

                                    </div>
                                </div>

                            </div>
                            <div className="profile_name">


                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                    <ul className="activity-list">
                                        {
                                            posts.map((post) => (
                                                <Post post={post} manifest={manifest} key={post.transactionHash+new Date().getTime()}/>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </section>

                </div>

                <a href="#" id="back-to-top"></a>

            </div>

        </div>

    )

}

export default ProfileView;
