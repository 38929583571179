import React from 'react';



import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import Header from './components/header.js';
import Footer from './components/footer.js';

import CryptoClass from './services/Crypto';
import ApiClass from './services/Api';

const Crypto = new CryptoClass();
const Api = new ApiClass();
const manifest = {Crypto, Api};

const host = window.location.hostname
manifest.stage = (host == "etrnl.it") ? 'prod' : 'dev';

window.Crypto = Crypto;
window.Api = Api;
window.manifest = manifest;

const sessStr = localStorage.getItem('eternalSession');
if(sessStr){
    console.log('sessStr: ',sessStr);
    try{
        manifest.session = JSON.parse(sessStr);
    }
    catch(err){
        manifest.session = null;
    }

}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
    <BrowserRouter>
        <Header manifest={manifest}/>
        <App manifest={manifest}/>
        <Footer manifest={manifest}/>
    </BrowserRouter>
    </>
);

reportWebVitals();
