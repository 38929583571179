import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import Loader from '../components/loader.js';


function CreatePost({manifest}) {


    const [isLoading, setIsLoading] = useState(false);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [uploadFile, setUploadFile] = useState(null);
    const [fileType, setFileType] = useState(null);

    const navigate = useNavigate();
    const { profileName } = useParams();

    useEffect(() => {
        async function doUpload(){
            await uploadImageFile();
        }
        doUpload();
    },[uploadFile]);


     function handleFileChange(evt) {
         let files = evt.target.files; // FileList object
         let f = files[0];
         let reader = new FileReader();

         reader.onload = (function(theFile) {
             return async function(e) {
                 const file = e.target.result;

                 const type = theFile.type.split('/')[1];
                 if(type != 'png' && type != 'jpeg' && type != 'jpg'){
                     return alert('file format not currently supported');
                 }
                 document.getElementById('createPostImage').src = file;
                 setFileType(type);
                 setUploadFile(file);

             };
           })(f);
           reader.readAsDataURL(f);
       }

     async function getPresignedUrl(image_hash){
         var profile_name = profileName;
         var file_type = fileType;
         var address = localStorage.getItem('eternalActiveAddress');
         var session_token = localStorage.getItem('eternalSessionToken');

         console.log(address,session_token);
         if(!address || !session_token){
             return alert('Must be signed in w/ connected wallet to upload.');
         }
         try{

             const apiRes = await manifest.Api.createPresignedUrl({image_hash,profile_name,file_type,address,session_token});
             return apiRes.presigned_url;
         }
         catch(err){
             console.log(err);
             return;
         }
     }


       async function uploadImageFile(){
           try{


               setIsImageUploading(true);
               const f = document.getElementById('createPostFileUpload').files[0];
               let reader = new FileReader();

               reader.onload = (function(theFile) {
                   return async function(e) {

                       var image_hash = manifest.Crypto.web3.utils.soliditySha3(uploadFile);
                       console.log('image_hash: ',image_hash);

                       var presigned_url = await getPresignedUrl(image_hash);
                       if(!presigned_url) return alert('Please sign in to upload');

                       const file = e.target.result;
                       const uploadRes = await manifest.Api.uploadFile({presigned_url,file});

                       const prefix = (manifest.stage == "prod") ? '' : 'dev-';
                       const profileId = manifest.Crypto.nameToId(profileName);
                       const imgUrl = 'https://s3.us-west-2.amazonaws.com/'+prefix+'img.etrnl.it/'+profileId+'/'+image_hash+'.'+fileType;
                       console.log('imgUrl: ',imgUrl);
                       document.getElementById('createPostURLInput').value = imgUrl;

                       setIsImageUploading(false);
                       return;
                   };
                 })(f);

                 reader.readAsArrayBuffer(f);
             }
             catch(err){
                 setIsImageUploading(false);
             }
       }


     function onCreatePostBtnClick(event){
         // debugger;
         // if(!Crypto.account){
         //     return Crypto.load();
         // }




         const contentInput = document.getElementById('createPostContentInput');
         const urlInput = document.getElementById('createPostURLInput');

         const content = contentInput.value;
         const url = urlInput.value;

         if( content == ""){
             return alert ('Post content is required');
         }

         const postObj = {
             message: {
                 "m": content
             },
             profileName
         };

         if(url.length > 0) postObj.message["u"] = url;

         async function doCreatePost(){
             if(!manifest.Crypto.account){
                 await manifest.Crypto.load();
             }
             else{
                 setIsLoading(true);
                 try{
                     await manifest.Crypto.load();
                     var res = await manifest.Crypto.createPost(postObj);
                     setIsLoading(false);
                     console.log('createPost res: ',res);
                     contentInput.value = '';
                     urlInput.value = '';
                     var newPath = "/profile/"+profileName;
                     navigate(newPath);
                 }
                 catch(err){
                     console.log('err: ',err);
                     setIsLoading(false);
                     alert('Something went wrong, post failed')
                 }

             }

         }
         doCreatePost();
     }
    return (

        <div className="de-retro">
            <div className="wrapper">
                <div className="no-bottom no-top content" >
                    <section id="subheader">
                            <div className="center-y relative text-center">
                                <div className="container">
                                    <div className="row">

                                        <div className="col-md-12 text-center">
        									<h1>Create Post </h1>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                    </section>

                    <section id="section-main" aria-label="section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 offset-lg-2">
                                    <form id="form-create-post" className="form-border" >

                                        <div className="col-lg-8 mb-sm-20">
                                        {/*

                                            <h5>Post Title</h5>
                                            <input type="text" name="createPostTitleInput" id="createPostTitleInput" className="form-control" placeholder="Title of your post..." />
                                            */}
                                            <h2>@{profileName}</h2>
                                            <div className="spacer-20"></div>

                                            <h5>Post Content</h5>
                                            <textarea name="createPostContentInput" id="createPostContentInput" className="form-control" placeholder="What's on your mind?" />

                                            <div className="spacer-20"></div>


                                            <h5>Image / link URL</h5>

                                            <input type="file"
                                                onChange={handleFileChange}
                                                id="createPostFileUpload"
                                                accept="image/png, image/jpeg"
                                            />

                                            {
                                                (isImageUploading)
                                                ? <Loader loadMessage={"Uploading image..."}/>
                                                : ""
                                            }
                                            
                                            <img
                                                id="createPostImage"
                                                style={{"maxWidth": "100%"}}
                                            />



                                            {/*
                                            <div className="spacer-20"></div>
                                            <div>Or</div>
                                            <div className="spacer-20"></div>
                                            */}
                                            <input hidden={true} type="text" name="createPostURLInput" id="createPostURLInput" className="form-control" placeholder="URL link" />


                                        </div>


                                        <div className="spacer-30"></div>
                                        <div className="profile_name">
                                            {
                                                (isLoading)
                                                ? <Loader loadMessage={"Creating post..."}/>
                                                : <input type="button" onClick={onCreatePostBtnClick} className="btn-main" value="Create Post"/>
                                            }
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <a href="#" id="back-to-top"></a>

            </div>

        </div>

    )

}

export default CreatePost;
