import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link, useNavigate } from "react-router-dom";
import Loader from '../components/loader.js';

import ProfileCard from '../components/profileCard.js';


function Search({manifest}) {
    const navigate = useNavigate();
    const { searchValue } = useParams();

    const [profiles, setProfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
      console.log('search: ',searchValue);
      if(searchValue) document.getElementById('search_main_field').value = searchValue;

      //show the profiles based upon search value
      const isValidSearch = function(val){
          if(!val) return false;
          if(val.length > 8) {
              alert('Profile names cannot exceed 8 characters')
              return false;
          }
          if(!val.match(/^[0-9a-zA-Z]+$/)){
              alert('Profile names must be alphanumeric characters')
              return false;
          }
          return true;
      }

      if(isValidSearch(searchValue)){
          // setIsLoading(true);
          setProfiles([{
              name: searchValue,
              id: manifest.Crypto.nameToId(searchValue),
              nft_image_uri: "https://dev-img.etrnl.it/empty1.png"
          }])
      }
      else{
          setProfiles([]);
      }
    },[searchValue]);


    const onSearchClick = e => {
        e.preventDefault();
        const searchVal = document.getElementById('search_main_field').value;
        navigate("/search/"+searchVal.toLowerCase());
    }


    return (
        <div className="de-retro">
            <div id="wrapper">

                <div className="no-bottom no-top" id="content">
                    <div id="top"></div>

                    <section id="subheader">
                            <div className="center-y relative text-center">
                                <div className="container">
                                    <div className="row">

                                        <div className="col-md-12 text-center">
        									<h1>Search</h1>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                    </section>

        			<section aria-label="section">
                        <div className="container">
                            <div className="row ">

                                <form className="row form-dark form_quick_search searchField" onSubmit={onSearchClick}>
                                    <div className="col text-center">
                                        <input className="form-control" id="search_main_field" placeholder="Search Profiles..." type="text" />
                                        <Link onClick={onSearchClick} className="btn-submit" id="">
                                            <i className=" fa fa-search bg-color-secondary"></i>
                                        </Link>
                                        <div className="clearfix"></div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </section>



                    <section aria-label="section">
                        <div className="container">
                            <div className="row ">
                    <div className="col-md-12">


                        <div className="row">


                        {
                            (isLoading)
                            ? <Loader loadMessage={"Loading Search Results..."}/>
                            : profiles.map( profile => {
                                return <div key={profile.id} className=" col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                    <ProfileCard key={profile.id} profile={profile} manifest={manifest}/>
                                    </div>
                            })
                        }
                        </div>
                        </div>
                        </div>
                        </div>
                    </section>
                </div>

                <a href="#" id="back-to-top"></a>


            </div>



        </div>
    )

}

export default Search;
