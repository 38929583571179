

import {useEffect, useState} from 'react';
import { Link } from "react-router-dom";



function ProfileCard({manifest,profile}) {

    const [profileImg, setProfileImg] = useState(null);
    const [isAvailable, setIsAvailable] = useState(true);
    const [mintPrice, setMintPrice] = useState(0);

    useEffect(() => {

        async function loadProfileInfo(){
            const urlPrefix = (manifest.stage == 'prod') ? '' : 'dev-';
            const imgUrl = 'https://s3.us-west-2.amazonaws.com/'+urlPrefix+'img.etrnl.it/'+profile.id+'.png';
            setProfileImg(imgUrl);
            const price = await manifest.Crypto.getMintPrice(profile.name);
            setMintPrice(price);

            if(manifest.Crypto.eternal){
                console.log('profile.name: ',profile.name);

                const owner = await manifest.Crypto.getProfileOwner(profile.name);
                console.log('owner: ',owner);
                if(0x00 != owner){
                    setIsAvailable(false);
                }
            }

        }
        loadProfileInfo();
    },[]);

    return (

        <div >
            <div className="nft__item">
            <Link className="profileCardTitle" to={"/profile/"+profile.name} target="_blank">
                <h4>@{profile.name}</h4>
            </Link>

                {
                    (isAvailable)
                    ? <span className="profileCardAvailable"> Available </span>
                    : ""
                }

                {/*



                <div className="author_list_pp">
                    <a href="04_retro-author.html" data-bs-toggle="tooltip" data-bs-placement="top" title="Creator: Monica Lucas">
                        <img className="lazy" src="images/author/author-1.jpg" alt=""/>
                        <i className="fa fa-check"></i>
                    </a>
                </div>
                */}
                <div className="nft__item_wrap">
                    <div className="nft__item_extra">
                        <div className="nft__item_buttons">

                            <Link to={"/profile/"+profile.name} target="_blank">
                                <button>
                                Check Me Out
                                </button>
                            </Link>

                            {/*
                            <div className="nft__item_share">
                                <h4>Share</h4>
                                <a href="https://www.facebook.com/sharer/sharer.php?u=https://madebydesignesia.com/themes" target="_blank"><i className="fa fa-facebook fa-lg"></i></a>
                                <a href="https://twitter.com/intent/tweet?url=https://madebydesignesia.com/themes" target="_blank"><i className="fa fa-twitter fa-lg"></i></a>
                                <a href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site https://madebydesignesia.com/themes"><i className="fa fa-envelope fa-lg"></i></a>
                            </div>
                            */}
                        </div>
                    </div>
                    <Link to={"/profile/"+profile.name} target="_blank">
                    {/*
                        <img src={profileImg} className=" " alt=""/>
                        */}
                        <img src={profileImg}
                        className="image-autosize img-fluid img-rounded mb-sm-30 pt40"
                        onError={(e: any) => e.target.src = 'https://dev-img.etrnl.it/empty1.png'}
                        alt=""
                        />

                    </Link>
                </div>
                <div className="nft__item_info">

                    <div className="nft__item_click">
                        <span></span>
                    </div>

                    {
                        (mintPrice)
                        ? <div className="nft__item_price">
                            {mintPrice} ETH
                        </div>
                        : ""
                    }

                    {/*
                    <div className="nft__item_action">
                        <a href="#">Place a bid</a>
                    </div>
                    <div className="nft__item_like">
                        <i className="fa fa-heart"></i><span>50</span>
                    </div>
                    */}
                </div>
            </div>
        </div>



    )

}

export default ProfileCard;
