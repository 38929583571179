import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from 'react';

function EternalModal({manifest, title, content, show, setShow}) {

    const [showModal, setShowModal] = useState(show);
    // setShowModal(show);
    const handleClose = () => {
        setShowModal(false);
        setShow(false);
    }
    const handleShow = () => {
        setShowModal(true);
        setShow(true);
    }

    useEffect(() => {
        setShowModal(show);
        setShow(show);
    },[show]);

    return (

        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {content}
          </Modal.Body>
        </Modal>
    )

}

export default EternalModal;
