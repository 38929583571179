import React, { useState, useEffect } from 'react';

import { useNavigate, useLocation } from "react-router-dom";


function PostUrl({post}) {
    const navigate = useNavigate();
    const location = useLocation();
    let contentObj = JSON.parse(post.content);
    let rawUrl = contentObj.u;

    const [imgId, setImgId] = useState(Math.random());
    const [isValidImg, setIsValidImg] = useState(false);
    const [wrapperHeight, setWrapperHeight] = useState('600px');
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });


    useEffect(() => {
       function handleResize() {
         setWindowSize({
           width: window.innerWidth,
           height: window.innerHeight,
         });
       }
       window.addEventListener("resize", handleResize);
       handleResize();
       return () => window.removeEventListener("resize", handleResize);
     }, []); // Empty array ensures that effect is only run on mount


    useEffect(() => {
        var newImgHeight = document.getElementById(imgId).height;
        setWrapperHeight(newImgHeight+"px");
    },[windowSize]);


    function onImgLoad(ev){
        setIsValidImg(true);
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
    }

    function onImgError(ev) {
        setWrapperHeight("0px");
        setIsValidImg(false);
    }

    return (
        <div style={{"height":wrapperHeight}} className="postUrlWrapper">

            <a href={post.data.u} target="_blank">
                <img
                    id={imgId}
                    className="postImg"
                    src={post.data.u}
                    onLoad={onImgLoad}
                    onError={onImgError}
                    style={{
                        "display":(isValidImg) ? "block": "none"
                    }}
                />

                <span style={{"display":(isValidImg) ? "none": "block"}}>
                    {post.data.u}
                </span>
            </a>

        </div>


    )

}



export default PostUrl;
