
import axios from 'axios';


class Api {

    async getUrl (){
        // var network = await window.web3.eth.net.getNetworkType();
        var host = window.location.hostname;

        let url = '';
        if('etrnl.it' == host){
            url = 'https://api.etrnl.it';
        }
        else{
            url = 'https://dev-api.etrnl.it';
        }
        return url;
    }

    async signIn({address,sig,msg,session_token}) {
        try {
            if(!address) return;
            const url = await this.getUrl();
            const response = await axios({
                  method: 'post',
                  url: url+'/accounts/'+address,
                  headers: {
                      "Content-Type": "application/json",
                  },
                  data: {
                    msg,
                    sig,
                    session_token
                  }
              });
              return response.data.item;
        }
        catch(err){
            console.log(err);
        }
    }

    async getAllProfiles(){
        try {
            const url = await this.getUrl();
            const response = await axios({
                  method: 'get',
                  url: url+'/profiles',
                  // params: { names: _profiles },
                  headers: {
                      "Content-Type": "application/json"
                  }
              });
              return response.data.items;
        }
        catch(err){
            console.log(err);
        }
    }

    async getAvailableProfiles(){
        try {
            const url = await this.getUrl();
            const response = await axios({
                  method: 'get',
                  url: url+'/profiles',
                  params: { available: true },
                  headers: {
                      "Content-Type": "application/json"
                  }
              });
              return response.data.items;
        }
        catch(err){
            console.log(err);
        }
    }
    async backfillProfile({profileName}) {
        try {
            const url = await this.getUrl();
            const response = await axios({
                  method: 'get',
                  url: url+'/services/backfill',
                  headers: {
                      "Content-Type": "application/json",
                  },
                  params: {
                    profileName
                  }
              });
              return response.data.item;
        }
        catch(err){
            console.log(err);
        }
    }

    async createPresignedUrl({profile_name,file_type,image_hash,address,session_token}) {
        try {
            const url = await this.getUrl();
            const response = await axios({
                  method: 'post',
                  url: url+'/services/presignedUrl',
                  headers: {
                      "Content-Type": "application/json",
                  },
                  data: {
                    file_type,
                    image_hash,
                    address,
                    profile_name,
                    session_token
                  }
              });
              return response.data.item;
        }
        catch(err){
            console.log(err);
        }
    }

    async uploadFile({presigned_url, file}){
        console.log('inside uploadFile: ',presigned_url)
        // const result = await axios.put(presigned_url, file)
        //     .catch(error => console.error(error.response.data, {request: error.request}));
        //
        // console.info(result.data);
        // return result.data;

        try{
            const response = await axios({
                method: 'put',
                data: file,
                url: presigned_url,
                headers: {
                    'Content-Type': 'image/png'
                }
            })

        }
        catch(err){
            console.log(err);

        }
    }




    // async getAccount({address,token}) {
    //     try {
    //         if(!address) return;
    //         const url = await this.getUrl();
    //         const response = await axios({
    //               method: 'get',
    //               url: url+'/accounts/'+address,
    //               headers: {
    //                   "Content-Type": "application/json",
    //                   "Authorization": "Token "+token
    //               }
    //           });
    //           return response.data.item;
    //     }
    //     catch(err){
    //         console.log(err);
    //     }
    // }

    // async updateProfile({profile_name,token,profile_image_url}){
    //     try {
    //         if(!profile_name) return;
    //         const url = await this.getUrl();
    //         const requestObj = {
    //             method: 'put',
    //             url: url+'/profiles/'+profile_name,
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": "Token "+token
    //             },
    //             data: {}
    //         };
    //
    //         if(profile_image_url){
    //             requestObj.data.profile_image_url = profile_image_url;
    //         }
    //         const response = await axios(requestObj);
    //
    //         return response.data.item;
    //     }
    //     catch(err){
    //         console.log(err);
    //     }
    // }


    // async getProfile(profile_name){
    //     try {
    //         const url = await this.getUrl();
    //         const response = await axios({
    //               method: 'get',
    //               url: url+'/profiles/'+profile_name,
    //               headers: {
    //                   "Content-Type": "application/json"
    //               }
    //           });
    //           return response.data.item;
    //     }
    //     catch(err){
    //         console.log(err);
    //     }
    // }


};


export default Api;
