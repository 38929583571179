import React, { useState, useEffect } from 'react';
import Post from '../components/post.js';
import Sidebar from '../components/sidebar.js';
import Loader from '../components/loader.js';

function Posts({manifest}) {

    const [allPosts, setAllPosts] = useState([]);

      useEffect(() => {

          const doLoadAllPosts = async () => {
              if(!manifest.Crypto.eternal){
                  await manifest.Crypto.load();
              }
              var allPosts = await manifest.Crypto.getAllPosts();
              console.log('all posts: ',allPosts);

              //get array of unique profile names
              // let profileNames = [];
              // for(var x in allPosts){
              //     let name = allPosts[x].profileName;
              //     if(-1 == profileNames.indexOf(name)) profileNames.push(name);
              // }
              //
              // //get the profile image for list of profile names
              // var profiles = await manifest.Api.getProfiles(profileNames);
              //
              // //create a name mapping so it's easier to associate
              // var nameObj = {};
              // for(var x in profiles){
              //     nameObj[profiles[x].name.toString()] = profiles[x].profile_image_url;
              // }
              // console.log('nameObj: ',nameObj);
              //update the profile_image_url
              // for(var x in allPosts){
              //     const profName = allPosts[x].profileName;
              //     allPosts[x].profile_image_url = nameObj[profName];
              // }
              setAllPosts(allPosts);
          }
          doLoadAllPosts();
      },[]);

    return (
        <div className="de-retro">
            <div className="wrapper">
                <div className=" no-top" id="">

                    <section id="subheader">
                            <div className="center-y relative text-center">
                                <div className="container">
                                    <div className="row">

                                        <div className="col-md-12 text-center">
                                            <h1>Posts</h1>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                    </section>
                    {
                        (!allPosts.length)
                        ? <Loader loadMessage={"Connect your web3 wallet to view..."}/>
                        : ''
                    }

                    <section aria-label="section">
                        <div className="container">
                            <div className="row">

                                <div className="col-md-8">
                                    <ul className="activity-list">
                                    {
                                        allPosts.map((post) => (
                                            <Post post={post} manifest={manifest} key={post.transactionHash+post.profileId+new Date().getTime()}  />
                                        ))
                                    }
                                    {/*
                                        <li className="act_follow">
                                            <img className="lazy" src="images/author/author-1.jpg" alt=""/>
                                            <div className="act_list_text">
                                                <h4>Monica Lucas</h4>
                                                started following <a href="#">Gayle Hicks</a>
                                                <span className="act_list_date">
                                                    10/07/2021, 12:40
                                                </span>
                                            </div>
                                        </li>
                                        <li className="act_sale">
                                            <img src="images/items/thumbnail-2.jpg" alt=""/>
                                            <div className="act_list_text">
                                                <h4>Deep Sea Phantasy</h4>
                                                1 edition purchased by <a href="#">Stacy Long</a> for 0.001 ETH
                                                <span className="act_list_date">
                                                    10/07/2021, 12:40
                                                </span>
                                            </div>
                                        </li>
                                        <li className="act_like">
                                            <img src="images/items/thumbnail-7.jpg" alt=""/>
                                            <div className="act_list_text">
                                                <h4>Cute Astronout</h4>
                                                liked by <a href="#">Nicholas Daniels</a>
                                                <span className="act_list_date">
                                                    10/07/2021, 12:40
                                                </span>
                                            </div>
                                        </li>
                                        <li className="act_follow">
                                            <img className="lazy" src="images/author/author-2.jpg" alt=""/>
                                            <div className="act_list_text">
                                                <h4>Mamie Barnett</h4>
                                                started following <a href="#">Claude Banks</a>
                                                <span className="act_list_date">
                                                    10/07/2021, 12:40
                                                </span>
                                            </div>
                                        </li>
                                        <li className="act_offer">
                                            <img src="images/items/thumbnail-5.jpg" alt=""/>
                                            <div className="act_list_text">
                                                <h4>Purple Planet</h4>
                                                <a href="#">Franklin Greer</a> offered 0.002 ETH
                                                <span className="act_list_date">
                                                    10/07/2021, 12:40
                                                </span>
                                            </div>
                                        </li>
                                        <li className="act_follow">
                                            <img className="lazy" src="images/author/author-3.jpg" alt=""/>
                                            <div className="act_list_text">
                                                <h4>Nicholas Daniels</h4>
                                                started following <a href="#">Franklin Greer</a>
                                                <span className="act_list_date">
                                                    10/07/2021, 12:40
                                                </span>
                                            </div>
                                        </li>
                                        <li className="act_sale">
                                            <img src="images/items/thumbnail-4.jpg" alt=""/>
                                            <div className="act_list_text">
                                                <h4>Two Tigers</h4>
                                                1 edition purchased by <a href="#">Jimmy Wright</a> for 0.02 ETH
                                                <span className="act_list_date">
                                                    10/07/2021, 12:40
                                                </span>
                                            </div>
                                        </li>
                                        <li className="act_like">
                                            <img src="images/items/thumbnail-6.jpg" alt=""/>
                                            <div className="act_list_text">
                                                <h4>Cute Astronout</h4>
                                                liked by <a href="#">Karla Sharp</a>
                                                <span className="act_list_date">
                                                    10/07/2021, 12:40
                                                </span>
                                            </div>
                                        </li>

                                        */}
                                    </ul>
                                </div>

                                {/*
                                    <Sidebar />
                                */}

                            </div>

                        </div>
                    </section>

                </div>

                <a href="#" id="back-to-top"></a>

            </div>

        </div>



    )

}

export default Posts;
