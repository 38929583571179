

import {useEffect, useState} from 'react';
import { Link } from "react-router-dom";


function About({Crypto}) {

    const [profileCount, setProfileCount ] = useState(999);
    const [postCount, setPostCount ] = useState('270k');
    const [visitorCount, setVisitorCount ] = useState('500m');

    // useEffect(() => {
    //     const loadScript = async (src) => {
    //       var tag = document.createElement('script');
    //       tag.async = false;
    //       tag.src = src;
    //       var body = document.getElementsByTagName('body')[0];
    //       body.appendChild(tag);
    //       return;
    //   };
    //
    //     async function asyncCall(){
    //         await loadScript('js/plugins.js');
    //         await loadScript('js/designesia.js');
    //     }
    //     asyncCall();
    //  }, []);

    return (
        <div className="de-retro">
            <div className="wrapper">

                <section id="subheader">
                        <div className="center-y relative text-center">
                            <div className="container">
                                <div className="row">

                                    <div className="col-md-12 text-center">
                                        <h1>Whitepaper</h1>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                </section>
                <section>
                    <div className="container wpContainer">

                        <h3>Abstract</h3>
                        <span>
                            Eternal is an NFT permissioned social platform built on the Ethereum Blockchain. Each Profile is represented by an ERC-1155 NFT, which authorizes the owner to Post message data to the Eternal contract. The platform is secure (authenticated via NFT), flexible (arbitrary JSON data structure), cost-efficient (utilizing Ethereum’s event logging system), Uncensorable (smart contract requires no intermediary), Immutable(verifiable w/ transaction hash), Permanent (posts live on-chain), Monetizable ( built-in social token minting) and Tradeable (Profiles can be traded like normal NFTs).
                        </span>
                    </div>
                    <div className="container wpContainer">
                        <h3>Introduction</h3>
                        <span>
                        ~5,100 years ago, the Sumerians invented the first known cuneiform language written on clay tablets. Similarly to blockchain technologies today, it was initially used as an accounting system and later expanded to support other use cases.
                        <br/>
                        <br/>
                        ~2,000 years ago, the greatest collection of written knowledge in the world was assembled at the library of Alexandria. Contrary to popular belief, it wasn’t a single fire that destroyed the library's collections, but rather centuries of neglect. Entropy, through the cruel passage of time, destroyed humanity's records of the prehistoric era.
                        <br/>
                        <br/>
                        ~1,600 years ago the Irish invented a system of maintaining records carved into stone called “Ogham Stones”. While these records have proved to be far more resilient than the papyrus scrolls at Alexandria, the speed at which data could be written and transferred left much to be desired.
                        <br/>
                        <br/>
                        These examples from history teach us a few important lessons:
                        Advanced societies increasingly depend upon data storage and transmission technologies for record keeping and communication systems.
                        When it comes to choosing a medium to transmit data, There seems to be a tradeoff between bandwidth (digital and papyrus are easily written) vs longevity (stone and clay tablets last for eons).
                        Understanding our past is greatly facilitated by the records that survive.
                        <br/>
                        <br/>
                        The future of humanity will benefit from an open record system that has high bandwidth and can survive the test of time.
                        <br/>
                        <br/>
                        A trend we are seeing today is the consolidation of data storage systems into fewer and larger multinational corporations. These corporations have become gatekeepers of our social dialog, while operating with ulterior motives, questionable ethics and enabling illegal government surveillance. We depend on data stored in their servers to operate our lives and store our records. Issues related to censorship, political leverage and control have grown to a point where it becomes necessary to consider an alternative.
                        <br/>
                        <br/>
                        In this paper we propose a platform built on the Ethereum blockchain which circumvents existing data monopolies to benefit humanity today and into the indefinite future.

                        </span>
                    </div>
                    <div className="container wpContainer">
                        <h3>Benefits</h3>
                        <span>
                        Below we discuss some of the benefits of the Eternal platform.
                        <br/>
                        <br/>
                        <b>Secure</b>
                        <br/>
                        Eternal utilizes EIP-1155 Multi-token standard to authorize the creation of and posting to secure Eternal Profiles (NFT). Profiles represent a single stream of posts created by the NFT holder. Profile posts are secured by the Ethereum blockchain and can be trusted to have originated from the Profile owner.
                        <br/>
                        <br/>
                        <b>Flexible</b>
                        <br/>
                        Eternal is a platform built with flexibility built into its core. The future of social networks and archiving, be it in the physical realm or in the Metaverse, will have use cases that can’t be known today. By providing an open data schema, Eternal encourages new functionality to be built on top.
                        <br/>
                        <br/>
                        <b>Cost-efficient</b>
                        <br/>
                        Eternal utilizes Ethereum’s event logging system for data storage, achieving massive cost saving advantages. By avoiding the updating of byte-space on-chain, we accomplish the goal of creating permanent digital records without the associated costs. This is a revolutionary approach that we expect to be duplicated by other protocols.
                        <br/>
                        <br/>
                        <b>Uncensorable</b>
                        <br/>
                        A Profile owner can submit message calls directly to the Eternal smart contract without the use of an intermediary, yielding censorship impossible
                        <br/>
                        <br/>
                        <b>Immutable</b>
                        <br/>
                        Eternal’s posts are stored inside Ethereum transaction data, which creates a permanent record of the action on-chain in the form of event logs. Eternal posts can be verified using traditional block hash analysis. As a result of this approach, posts can never be altered or deleted without eliminating the integrity of the blockchain.
                        <br/>
                        <br/>
                        <b>Permanent</b>
                        <br/>
                        The Eternal contract (along with its associated Profiles and posts) will continue to exist on the Ethereum blockchain in perpetuity. Eternal adopts the strengths of Ethereum itself; it cannot be destroyed, bought, altered or censored. We expect the Ethereum blockchain and its influence on society to continue to expand well past the 21st century - Eternal will continue to flourish along with it.
                        <br/>
                        <br/>
                        <b>Monetizable</b>
                        <br/>
                        The Eternal smart contract enables Profile owners to mint social tokens associated with their Profile. This functionality unlocks new, previously unimaginable, monetization opportunities.
                        <br/>
                        <br/>
                        <b>Tradeable</b>
                        <br/>
                        Profiles are represented by NFTs, so they can be traded or leveraged like any other NFT. However, unlike most other NFTs, Eternal Profiles have real world utility that grows with increased usage and time.

                        </span>
                    </div>

                    <div className="container wpContainer">
                        <h3>Architecture</h3>
                        <span>
                            <b>
                            Eternal utilizes Ethereum’s event logging system to store the Profile posts.
                            </b>
                            <ul>
                                <li>
                                Event logs cannot be read directly from chain, but can be reconstructed and verified by checking the root hash of the “Receipt Trie”
                                </li>
                            </ul>

                            <b>
                            Each NFT has a unique Profile ID that is mapped from the Profile name.
                            </b>
                            <ul>
                            <li>
                            Each Profile is represented by the Profile ID and is indexed as a uint256, ensuring NFTs are unique to each Profile name
                            </li>

                            <li>
                            Any alphanumeric string of 8 characters or less can be converted to the corresponding Profile ID
                            </li>

                            <li>
                            Any Profile ID can be converted back to the alphanumeric Profile name
                            </li>

                            <li>
                            mapping function from Profile name -> Profile ID:
                            </li>

                            <li>
                            mapping function from Profile ID -> Profile name:
                            </li>
                            </ul>

                            <b>
                            Channels
                            </b>
                            <ul>
                            <li>
                            A Channel can be defined on each post. A Channel is a hex encoded bytes32 string that associates the Post with a high level topic. Channels enable the filtering of Post streams directly from the Web3 library. There is contract level validation of Channel data - any Profile may Post to any Channel.
                            </li>
                            </ul>

                            <b>
                            Arbitrary data structures are supported
                            </b>
                            <ul>
                            <li>
                            Posting data structure is not strictly enforced, but it should be valid JSON string.
                            </li>
                            <li>
                            The etrnl.it website reads message data as JSON objects that follow the following schema:
                            <ul>
                            <li>
                            &#123;
                            </li>
                            <li>
                             “t”: "Title of the post. This field is typically highlighted or displayed in larger font",
                             </li>
                             <li>

                             “m”: "Message body of the post. This field is typically displayed in a smaller font size"
                             </li>
                             <li>

                             “u”: "URI of an image or other media resource that will be displayed with this post",
                             </li>
                             <li>
                            “r”: "Reference to another post (Post Id). This field is used to support the ability to comment on another post"
                             </li>
                             <li>
                             &#125;
                             </li>
                             </ul>

                            </li>


                             <li>
                             You can pass any arbitrary data format and consume the data on your own website
                             </li>
                             <li>
                            Every field is optional
                            </li>
                            <li>
                            Other fields may be supported in the future.
                            </li>
                            </ul>


                        </span>
                    </div>

                    <div className="container wpContainer">
                        <h3>Limitations</h3>
                        <span>
                        Next we discuss a few of the limitations and tradeoffs that were made in the development of Eternal.
                        <br/>
                        <br/>

                        <b>
                        Using Ethereum’s event logging system to store the Profile posts:
                        </b>
                        <ul>
                        <li>
                        Advantage: primarily cost-savings - data posted to the event log is orders of magnitude less expensive than updating data on-chain.
                        </li>
                        <li>
                        Disadvantage: the Post data itself is unavailable to smart contracts. The data must be retrieved through scanning log data, which is inaccessible from inside the Ethereum Virtual Machine.
                        </li>
                        </ul>

                        <b>
                        Using NFTs to represent Profiles:
                        </b>
                        <ul>
                        <li>
                        Advantage: by following pre-existing standards, the Eternal contract has two primary advantage:
                            <ol>
                            <li>
                            trustable, secure and easily auditable
                            </li>
                            <li>
                            highly liquid (can be easily traded on existing NFT marketplaces)
                            </li>
                            </ol>
                        </li>
                        <li>
                        Disadvantage: Minting NFTs comes with additional computational overhead, slightly increasing costs required to create and operate Profiles.
                        </li>
                        </ul>

                        <b>
                        Using ERC-1155 multi-token standard:
                        </b>
                        <ul>
                        <li>
                        Advantage: The ERC-1155 multi-token standard enables profile owners to mint their own social tokens which are uniquely associated with their profile.
                        </li>
                        <li>
                        Disadvantage: The ERC-1155 standard lacks some of the standard contract calls available in the ERC-721 standard. For example, the “getOwner(_tokenId)” function does not exist in ERC-1155.
                        </li>
                        </ul>

                        <b>
                        Restricting profile names to be 8 alpha-numeric characters or less:
                        </b>
                        <ul>
                        <li>
                        Advantage: Profile names are easily remembered and understandable.
                        Also, Profile IDs are guaranteed to fit inside Javascript’s Integer maximum size, making it easy to convert between Profile names and Profile IDs on the frontend.
                        </li>
                        <li>
                        Disadvantage: Profile names longer than 8 characters and special characters are not supported.
                        </li>
                        </ul>


                        </span>
                    </div>

                    <div className="container wpContainer">
                        <h3>Use Cases</h3>
                        <span>
                        The Eternal platform is ideal for any application that requires public, permanent, immutable, digital records. There are an endless number of use cases which can be built upon the Eternal protocol, we will list a few hypothetical use cases that an Eternal Profile can support:
                        <br/>
                        <br/>
                        <b>
                        Social Media:
                        </b>
                        <ul>
                        <li>
                        Twitter alternative
                        </li>
                        <li>
                        Reddit alternative
                        </li>
                        <li>
                        Pinterest alternative
                        </li>
                        <li>
                        Blogging
                        </li>
                        </ul>

                        <b>
                        Business Services:
                        </b>
                        <ul>
                        <li>
                        Prediction markets
                        </li>
                        <li>
                        Knowledge repository
                        </li>
                        <li>
                        Insurance
                        </li>
                        <li>
                        Supply-chain management
                        </li>
                        <li>
                        Inventory systems
                        </li>
                        <li>
                        Corporate communication
                        </li>
                        <li>
                        Copyright usage
                        </li>
                        <li>
                        Patent (first to invent)
                        </li>
                        <li>
                        Classified ads
                        </li>
                        </ul>

                        <b>
                        Legal:
                        </b>
                        <ul>
                        <li>
                        Legal Contracts
                        </li>
                        <li>
                        Agreement execution
                        </li>
                        <li>
                        Notary services
                        </li>
                        <li>
                        Living wills
                        </li>
                        <li>
                        Software licenses
                        </li>
                        </ul>


                        <b>
                        Personal:
                        </b>
                        <ul>
                        <li>
                        Record keeping / documentation
                        </li>
                        <li>
                        Weddings
                        </li>
                        <li>
                        Births
                        </li>
                        <li>
                        Obituaries
                        </li>
                        <li>
                        Genealogy
                        </li>
                        <li>
                        Memory repository
                        </li>
                        <li>
                        Love letters
                        </li>
                        <li>
                        Prayers
                        </li>
                        <li>
                        Message for future self/children
                        </li>
                        <li>
                        Holiday messages
                        </li>
                        <li>
                        Accountability
                        </li>
                        </ul>


                        <b>
                        Government:
                        </b>
                        <ul>
                        <li>
                        Voting systems
                        </li>
                        <li>
                        Procurement
                        </li>
                        <li>
                        Public notices
                        </li>
                        <li>
                        Annual reports
                        </li>
                        <li>
                        Automobile registration records
                        </li>
                        <li>
                        Census records
                        </li>
                        <li>
                        Property deeds
                        </li>
                        <li>
                        Titles
                        </li>
                        <li>
                        Court records
                        </li>
                        <li>
                        Citizenship records
                        </li>
                        <li>
                        Tax records
                        </li>
                        <li>
                        Criminal records
                        </li>
                        <li>
                        Veteran records
                        </li>
                        <li>
                        Political contributions
                        </li>
                        <li>
                        Municipal budgets
                        </li>
                        <li>
                        Library records
                        </li>
                        <li>
                        Birth certificates
                        </li>
                        <li>
                        Death certificates
                        </li>
                        <li>
                        Digital passports
                        </li>
                        </ul>


                        <b>
                        Culture:
                        </b>
                        <ul>
                        <li>
                        Time capsules
                        </li>
                        <li>
                        Major events
                        </li>
                        <li>
                        World news
                        </li>
                        <li>
                        Public Announcements
                        </li>
                        </ul>


                        <b>
                        Other:
                        </b>
                        <ul>
                        <li>
                        University degrees
                        </li>
                        <li>
                        Proof of certifications
                        </li>
                        <li>
                        Whistleblowers
                        </li>
                        <li>
                        Charities/donations
                        </li>
                        <li>
                        Public Health records
                        </li>
                        </ul>



                        </span>
                    </div>

                    <div className="container wpContainer">
                        <h3>Conclusion</h3>
                        <span>
                        Through the combination of security, flexibility, cost-efficiency, lack of censorship, permanence, monetization and tradeability, Eternal offers a strong decentralized alternative to the modern social media networks.

                        </span>
                    </div>


                </section>

            </div>

        </div>



    )

}

export default About;
