import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import Loader from '../components/loader.js';
import ProfileMint from '../components/profileMint.js';
import ProfileView from '../components/profileView.js';


function Profile({manifest}) {
    const navigate = useNavigate();
    const { profileName } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [posts, setPosts] = useState([]);

      useEffect(() => {

          const loadProfileInfo = async () => {
              if(!manifest.Crypto.eternal){
                  await manifest.Crypto.load();
              }

              var posts = await manifest.Crypto.getPostsForProfile(profileName);
              setPosts(posts);

              setIsLoading(false);
          }
          loadProfileInfo();
      },[profileName]);

    return (
        <>
        {
            (isLoading) ? <Loader loadMessage={"Loading Profile..."}/>
            : (!posts.length)
            ? <ProfileMint manifest={manifest} profileName={profileName}/>
           : <ProfileView manifest={manifest} profileName={profileName} posts={posts}/>
        }
    </>
    )

}

export default Profile;
