
import {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import Loader from '../components/loader.js';


function ProfileMint({manifest,profileName}) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [mintPrice, setMintPrice] = useState("...");
    const [profileImg, setProfileImg] = useState('');
    const [profileDesc, setProfileDesc] = useState('');
    const [artistProfileName, setArtistProfileName] = useState('eternal');
    const [artistProfileId, setArtistProfileId] = useState(manifest.Crypto.nameToId('eternal'));
    const [collectionName, setCollectionName] = useState('None');
    const [allMintPrices, setAllMintPrices] = useState([]);

    useEffect(() => {
          const loadProfileInfo = async () => {
              // if(!manifest.Crypto.eternal){
              //     await manifest.Crypto.load();
              // }

              const id = await manifest.Crypto.nameToId(profileName);
              const envImg = (manifest.stage == 'prod') ? '' : 'dev-';
              setProfileImg('https://'+envImg+'img.etrnl.it/'+id+'.png');

              const mintPrice = await manifest.Crypto.getMintPrice(profileName);
              setMintPrice(mintPrice)

              const allPricesWei = await manifest.Crypto.getMintPrices();
              const allPricesEth = [];
              for(var x in allPricesWei){
                  allPricesEth.push(allPricesWei[x]/1000000000000000000);
              }
              setAllMintPrices(allPricesEth);
          }
          loadProfileInfo();
    },[profileName]);


    function claimProfile (){
        async function doClaim (){
            if(profileName.length > 8) {
                return alert('Profiles must be 8 characters or less');
            }
            else if(!manifest.Crypto.account){
                await manifest.Crypto.load();
            }
            else{
                setIsLoading(true);
                try{
                    await manifest.Crypto.load();
                    const status = await manifest.Crypto.mintProfile(profileName);
                    const apiRes = await manifest.Api.backfillProfile({profileName});
                    setIsLoading(false);
                    alert('Congratulations! You are the owner of '+profileName);
                    window.location.reload(false);
                }
                catch(err){
                    alert('minting failed...');
                    setIsLoading(false);
                }
            }
        }
        doClaim();
    }

    return (

        <div className="de-retro">
            <div className="wrapper">

                <div className="no-bottom no-top content" id="">
                    <div id="top"></div>

                    <section id="nft-item-details" aria-label="section" className="sm-mt-0">
                        <div className="container">
                            <div className="row g-5">
                                <div className="col-md-6 text-center">

                                    <div className="nft-image-wrapper">
                                        <img src={profileImg}
                                        className="image-autosize img-fluid img-rounded mb-sm-30"
                                        onError={(e: any) => e.target.src = 'https://dev-img.etrnl.it/empty1.png'}
                                        alt=""
                                        />
                                    </div>
                                    <span>
                                        {/*
                                            *You can upload a custom NFT image after minting
                                        */}
                                    </span>

                                </div>
                                <div className="col-md-6">
                                    <div className="item_info">
                                        <h2>@{profileName}</h2>
                                        {/*
                                        <div className="item_info_counts">
                                            <div className="item_info_type"><i className="fa fa-image"></i>Art</div>
                                            <div className="item_info_views"><i className="fa fa-eye"></i>250</div>
                                            <div className="item_info_like"><i className="fa fa-heart"></i>18</div>
                                        </div>
                                        */}
                                        <p>{profileDesc}</p>
                                        <div className="d-flex flex-row">
                                        {
                                            (artistProfileName.length)
                                            ?
                                                <div className="mr40">
                                                    <h6>Creator</h6>
                                                    <div className="item_author">
                                                        <div className="author_list_pp">
                                                            <Link to={"/profile/"+artistProfileName} target="_blank">
                                                                <img className="lazy" src={"https://dev-img.etrnl.it/"+artistProfileId+'.png'} alt=""/>
                                                                <i className="fa fa-check"></i>
                                                            </Link>
                                                        </div>
                                                        <div className="author_list_info">
                                                            <Link to={"/profile/"+artistProfileName} target="_blank">
                                                                @{artistProfileName}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            : ""
                                        }
                                        {
                                            (collectionName.length)
                                            ? <div>
                                                <h6>Collection</h6>
                                                <div className="item_author">
                                                    <div className="author_list_pp">
                                                        <Link to={"/collections"} target="_blank">
                                                            <img className="lazy" src="../images/collections/coll-thumbnail-1.jpg" alt=""/>
                                                            <i className="fa fa-check"></i>
                                                        </Link>
                                                    </div>
                                                    <div className="author_list_info">
                                                        <Link to={"/collections"} target="_blank">
                                                            {collectionName}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                        }

                                        </div>


                                        <div className="de_tab tab_simple">



                                        {/*
                                            <div className="spacer-40"></div>
                                            <h6>Properties</h6>
                                            <div className="row gx-2">
                                                <div className="col-lg-4 col-md-6 col-sm-6">
                                                    <a href="#" className="nft_attr">
                                                        <h5>Background</h5>
                                                        <h4>Yellowish Sky</h4>
                                                        <span>85% have this trait</span>
                                                    </a>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6">
                                                    <a href="#" className="nft_attr">
                                                        <h5>Eyes</h5>
                                                        <h4>Purple Eyes</h4>
                                                        <span>14% have this trait</span>
                                                    </a>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6">
                                                    <a href="#" className="nft_attr">
                                                        <h5>Nose</h5>
                                                        <h4>Small Nose</h4>
                                                        <span>45% have this trait</span>
                                                    </a>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6">
                                                    <a href="#" className="nft_attr">
                                                        <h5>Mouth</h5>
                                                        <h4>Smile Red Lip</h4>
                                                        <span>61% have this trait</span>
                                                    </a>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6">
                                                    <a href="#" className="nft_attr">
                                                        <h5>Neck</h5>
                                                        <h4>Pink Ribbon</h4>
                                                        <span>27% have this trait</span>
                                                    </a>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6">
                                                    <a href="#" className="nft_attr">
                                                        <h5>Hair</h5>
                                                        <h4>Pink Short</h4>
                                                        <span>35% have this trait</span>
                                                    </a>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6">
                                                    <a href="#" className="nft_attr">
                                                        <h5>Accessories</h5>
                                                        <h4>Heart Necklace</h4>
                                                        <span>33% have this trait</span>
                                                    </a>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6">
                                                    <a href="#" className="nft_attr">
                                                        <h5>Hat</h5>
                                                        <h4>Cute Panda</h4>
                                                        <span>62% have this trait</span>
                                                    </a>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6">
                                                    <a href="#" className="nft_attr">
                                                        <h5>Clothes</h5>
                                                        <h4>Casual Purple</h4>
                                                        <span>78% have this trait</span>
                                                    </a>
                                                </div>
                                            </div>
                                            */}
                                            <div className="spacer-30"></div>


                                        <div className="spacer-30"></div>


                                        <h6 className="">Mint Price</h6>
                                        <div className="nft-item-price"><img src="../images/misc/ethereum.svg" alt=""/><span>{mintPrice}</span></div>
                                        {
                                            (isLoading) ?
                                                <Loader loadMessage={"Minting your profile NFT... (don't refresh page)"}/>
                                                : <button onClick={claimProfile} className="btn-main btn-lg lead profilePgMintBtn">Mint NFT Profile: @{profileName}</button>

                                        }
                                        <div className="spacer-30"></div>
                                        <span className="ethPriceText">* Mint prices are determined by the length of the profile name - ex:</span>
                                        {
                                            (allMintPrices)
                                            ? allMintPrices.map((price,index) => {
                                                return <div key={price} className="ethPriceText">
                                                            {'"'+'abcdefgh'.substr(0,index+1) +'" : '+price+' eth'}
                                                        </div>
                                            })
                                            : ""
                                        }
                                    </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

            </div>
        </div>

    )

}

export default ProfileMint;
