const {RingLoader} = require('react-spinners');

function Loader({manifest,loadMessage}) {

    return (

         <div className="de-retro">
            <div className="wrapper">
                <div className="no-bottom no-top content" >
                <section className="taCenter loaderSection">
                    <RingLoader className="profileRingLoader"/>
                    {loadMessage}
                </section>
                </div>
            </div>
        </div>
    )

}

export default Loader;
