import React, { useState, useEffect } from 'react';

import { useNavigate, useLocation } from "react-router-dom";

import PostUrl from '../components/postUrl.js';

function Post({post, manifest}) {
    const navigate = useNavigate();
    const location = useLocation();

    // const urlPrefix = ('prod' == manifest.stage) ? '' : 'dev-';
    // const imageUrl = 'https://'+urlPrefix+'img.etrnl.it/'+post.profileId+'.png';


    const [profileImg, setProfileImg] = useState(null);
    const [etherscanUrl, setEtherscanUrl] = useState(null);


    useEffect(() => {
        const stage = manifest.stage;
        const urlPrefix = (stage == 'prod') ? '' : 'dev-';
        const imgUrl = 'https://s3.us-west-2.amazonaws.com/'+urlPrefix+'img.etrnl.it/'+post.profileId+'.png';
        setProfileImg(imgUrl);

        async function doNetworkStuff(){
            const network = await manifest.Crypto.web3.eth.net.getNetworkType();
            const ethUrl = "https://"+(network == 'main' ? '' : network+'.')+"etherscan.io/tx/";
            setEtherscanUrl(ethUrl)
        }
        doNetworkStuff();
    },[]);

    function onPostClick(post){
        var currentPath = location.pathname;
        var newPath = "/profile/"+post.profileName;
        if(currentPath == newPath) return;
        navigate(newPath);
    }
    return (

        <li onClick={() => onPostClick(post)} className="act_follow">


            <img
                className="lazy postProfileImage"
                src={profileImg}
                onError={(e: any) => {setProfileImg('https://dev-img.etrnl.it/empty1.png')}}
                alt=""
            />

            <div className="act_list_text">
                <div className="postProfileName">
                    <h4>@{post.profileName}</h4>
                    <p>{post.channel}</p>
                </div>

                {/*
                <div>
                        {post.data.t}
                </div>
                */}
                <div className="postContent">
                    <span>
                        {post.data.m}
                    </span>
                </div>
                {
                    (post.data.u)
                    ?   <PostUrl post={post}/>
                    : ""
                }

                <div className="act_list_subtext">

                    <div>
                        <span>
                            Block #: {post.blockNumber}
                        </span>
                        <a href={etherscanUrl+post.transactionHash+"#eventlog"} className="viewOnEtherscan" target="_blank">
                            View on Etherscan
                        </a>

                    </div>
                </div>
                {/*
                <span className="act_list_date">
                    10/07/2021, 12:40
                </span>
                */}
            </div>
        </li>

    )

}

export default Post;
