
import logo from './images/icons/fav_24px.png'

import './css/bootstrap.min.css';
import './css/plugins.css';

import './css/style.css';
import './css/de-retro.css';
import './css/scheme-05.css';
import './css/coloring.css';

import React, { useState, useEffect } from 'react';
import { Routes, Route, Redirect, useLocation } from 'react-router-dom';

import About from './components/about.js';
import WhitePaper from './components/whitePaper.js';
import Posts from './components/posts.js'
import Profile from './components/profile.js';
import ProfileEdit from './components/profileEdit.js';
import CreatePost from './components/createPost.js';
import Collections from './components/collections.js';
import Search from './components/search.js';

import ReactGA from "react-ga4";
window.googleAnalyticsId = "G-CN6C3EHZ1G";
const host = window.location.hostname
const testMode = (host == "localhost" || host == "dev-www.etrnl.it");

ReactGA.initialize(window.googleAnalyticsId, {testMode});



const App = ({manifest}) => {

    const location = useLocation();

    useEffect(() => {
        trackPageView(); // To track the first pageview upon load
        // history.listen(trackPageView); // To track the subsequent pageviews
    }, [location]);

    function trackPageView() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }

    return (
        <>
        <Routes> {/* The Switch decides which component to show based on the current URL.*/}
            <Route exact path='/' element={<About manifest={manifest}/>} ></Route>
            <Route exact path='/about' element={<About manifest={manifest}/>} ></Route>
            <Route exact path='/whitePaper' element={<WhitePaper manifest={manifest}/>} ></Route>
            <Route exact path='/posts' element={<Posts manifest={manifest}/>} ></Route>
            <Route exact path='/profile/:profileName' element={<Profile manifest={manifest}/>} ></Route>
            <Route exact path='/profile/:profileName/createPost' element={<CreatePost manifest={manifest}/>} ></Route>
            <Route exact path='/profileEdit/:profileName' element={<ProfileEdit manifest={manifest}/>} ></Route>
            <Route exact path='/collections' element={<Collections manifest={manifest}/>} ></Route>
            <Route exact path='/search' element={<Search manifest={manifest}/>} ></Route>
            <Route exact path='/search/:searchValue' element={<Search manifest={manifest}/>} ></Route>
        </Routes>
        </>
    );
}

export default App;
