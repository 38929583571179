import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";

import Loader from '../components/loader.js';


function ProfileEdit({Crypto, Api, manifest}) {
    const navigate = useNavigate();
    const { profileName } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [profileImg, setProfileImg] = useState(null);
    const [fileType, setFileType] = useState(null);

    async function loadProfileImage(){
        const prefix = (manifest.stage == "prod") ? '' : 'dev-';
        const profileId = manifest.Crypto.nameToId(profileName);
        const imgUrl = 'https://s3.us-west-2.amazonaws.com/'+prefix+'img.etrnl.it/'+profileId+'.png';
        console.log('imgUrl: ',imgUrl);
        setProfileImg(imgUrl);
    }

    useEffect(() => {
        loadProfileImage();
    },[]);

    function handleFileChange(evt) {
        let files = evt.target.files; // FileList object
        let f = files[0];
        let reader = new FileReader();

        reader.onload = (function(theFile) {
            return async function(e) {
                const file = e.target.result;
                console.log('file type: ',theFile.type);
                const type = theFile.type.split('/')[1];
                if(type != 'png' && type != 'jpeg' && type != 'jpg'){
                    return alert('file format not currently supported');
                }

                setFileType(type);

                document.getElementById('profileEditImage').src = file;
            };
          })(f);
          reader.readAsDataURL(f);
      }


    async function getPresignedUrl(){
        var file_type = 'png'; //fix later - save everything as .png
        var profile_name = profileName;
        var address = localStorage.getItem('eternalActiveAddress');
        var session_token = localStorage.getItem('eternalSessionToken');
        // var profile_name = 'bootylic'
        console.log(address,session_token,profile_name);
        if(!address || !session_token){
            return alert('Must be signed in w/ connected wallet to upload.');
        }
        try{
            const apiRes = await manifest.Api.createPresignedUrl({profile_name,file_type,address,session_token});
            return apiRes.presigned_url;
        }
        catch(err){
            console.log(err);
            return;
        }
    }


      async function uploadImageFile(){

          try{
              setIsLoading(true);
              const f = document.getElementById('profileEditFileUpload').files[0];
              let reader = new FileReader();

              reader.onload = (function(theFile) {
                  return async function(e) {
                      var presigned_url = await getPresignedUrl();
                      if(!presigned_url) {
                          setIsLoading(false);
                          return alert('Please sign in to save');
                      }

                      const file = e.target.result;
                      const uploadRes = await manifest.Api.uploadFile({presigned_url,file});

                      loadProfileImage();

                      alert('Profile info saved');
                      setIsLoading(false);
                      return;
                  };
                })(f);

                reader.readAsArrayBuffer(f);
            }
            catch(err){
                setIsLoading(false);
            }
      }

    function onSaveClick(){

        uploadImageFile();
    }

    return (
        <>

        <div className="de-retro">
            <div className="wrapper">

                <div className="no-bottom no-top content" >

                    <section id="subheader">
                            <div className="center-y relative text-center">
                                <div className="container">
                                    <div className="row">

                                        <div className="col-md-12 text-center">
        									<h1>Edit Profile</h1>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                    </section>

                    <section id="section-main" aria-label="section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 offset-lg-2">
                                    <form id="form-create-item" className="form-border" method="post" action="email.php">
                                    <div className="de_tab tab_simple">


                                    {/*
                                        <ul className="de_nav">
                                            <li className="active"><span><i className="fa fa-user"></i>Profile</span></li>
                                            <li><span><i className="fa fa-exclamation-circle"></i>Notifications</span></li>
                                        </ul>
                                    */}
                                        <div className="de_tab_content">
                                            <div className="tab-1">
                                                <div className="row ">
                                                    <div className="col-lg-8 mb-sm-20">
                                                            <div className="field-set">

                                                            <div className="profile_name">
                                                                <h4>
                                                                    @{profileName}
                                                                    <div className="clearfix"></div>


                                                                </h4>
                                                            </div>
                                                            <br/>
                                                                <h5>Profile Image</h5>
                                                                * Square images are recommended
                                                                <div className="profile_avatar">
                                                                    <div className="d_profile_img">
                                                                        <img src={profileImg}
                                                                        id="profileEditImage"
                                                                        onError={(e: any) => {setProfileImg('https://dev-img.etrnl.it/empty1.png')}}
                                                                        alt=""/>
                                                                        {/* <i className="fa fa-check"></i> */}
                                                                    </div>

                                                                </div>

                                                                <br/>
                                                                <input type="file"
                                                                    onChange={handleFileChange}
                                                                       id="profileEditFileUpload"
                                                                       accept="image/png, image/jpeg"
                                                                />


                                                                {/*

                                                                <p className="p-info">Choose a custom profile image using one of your NFTs.</p>
                                                                <p className="p-info">Your ownership will be validated before being published.</p>
                                                                <input type="text" name="nftContract"  className="form-control" placeholder="NFT Contract Address" />
                                                                <input type="text" name="nftTokenId"  className="form-control" placeholder="NFT Token ID" />

                                                                <input type="radio" id="html" name="nftType" value="721"/>
                                                                  <label >ERC-721</label>
                                                                  <input type="radio" id="css" name="nftType" value="1155"/>
                                                                  <label >ERC-1155</label>
                                                                   */}


                                                                {/*
                                                                <div className="spacer-20"></div>
                                                                <div className="spacer-20"></div>

                                                                <h5>Tagline</h5>
                                                                <input type="text" name="tagline"  className="form-control" placeholder="Tagline appears next to your profile name" />

                                                                <div className="spacer-20"></div>

                                                                <h5>Bio</h5>
                                                                <textarea name="bio"  className="form-control" placeholder="Bio appears on your profile" ></textarea>

                                                                <div className="spacer-20"></div>

                                                                <h5>Email</h5>
                                                                <input type="text" name="email_address" className="form-control" placeholder="(hidden) email for notifications" />
                                                                */}
                                                            </div>
                                                    </div>
                                                    {/*
                                                    <div id="sidebar" className="col-lg-4">
                                                        <h5>Profile image <i className="fa fa-info-circle id-color-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Recommend 400 x 400. Max size: 50MB. Click the image to upload."></i></h5>

                                                        <img src="images/author_single/author_thumbnail.jpg" id="click_profile_img" className="d-profile-img-edit img-fluid" alt=""/>
                                                        <input type="file" id="upload_profile_img"/>

                                                        <div className="spacer-30"></div>

                                                        <h5>Profile banner <i className="fa fa-info-circle id-color-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Recommend 1500 x 500. Max size: 50MB. Click the image to upload."></i></h5>
                                                        <img src="images/author_single/author_banner.jpg" id="click_banner_img" className="d-banner-img-edit img-fluid" alt=""/>
                                                        <input type="file" id="upload_banner_img"/>

                                                    </div>
                                                    */}
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="spacer-30"></div>
                                    *Uploading a new profile image will overwrite your existing image
                                    <br/>
                                    {
                                        (isLoading)
                                            ? <Loader loadMessage={"Saving profile..."}/>
                                            : <input onClick={onSaveClick} type="button" className="btn-main" value="Save Profile"/>
                                    }

                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

                <a href="#" id="back-to-top"></a>

            </div>
        </div>
        </>


    )

}

export default ProfileEdit;
